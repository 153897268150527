import axios from 'axios';

import {getAccessTokenSilently} from 'shared/utils/token';
import {getAxiosOptions} from 'shared/utils/api';
import {Config} from 'shared/config';
import {IUpdateMarketingProfile} from '../models/marketing-profile';
import {IMarketingProfile} from '../models/marketing-profile';

export async function createMarketingProfile(tenantId: number, userData: IUpdateMarketingProfile): Promise<IUpdateMarketingProfile> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post('/', userData, getAxiosOptions(accessToken));
    return response.data;
}

const getProfileApiUrl = (tenantId: number, path: string) => {
    return `${Config.marketingServiceURL}/tenants/${tenantId}/profiles${path}`;
};

export const readProfile = async (tenantId: number, profileId: number): Promise<IMarketingProfile> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(
        getProfileApiUrl(tenantId, `/${profileId}`),
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export async function updateProfile(
    tenantId: number,
    profileId: string,
    updateData: IUpdateMarketingProfile,
): Promise<IMarketingProfile> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(
        getProfileApiUrl(tenantId, `/${profileId}`),
        updateData,
        getAxiosOptions(accessToken),
    );
    return response.data;
}

export const readProfileAsUser = async (tenantId: number, userId: number): Promise<IMarketingProfile> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(
        getProfileApiUrl(tenantId, `/users/${userId}`),
        getAxiosOptions(accessToken),
    );
    return response.data;
};