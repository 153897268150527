import {Button, Col, Form, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {Gender, IUpdateMarketingProfile, ProfileInterests} from 'modules/marketing-profile/models/marketing-profile';

import {FormError} from 'shared/components/forms/FormError/FormError';
import {Config} from 'shared/config';

const userRegistrationExtraInfoSchema = Yup.object().shape({
    gender: Yup.string().oneOf(Object.values(Gender)).required(),
    interests: Yup.array(Yup.string().oneOf(Object.values(ProfileInterests))).min(1),
    accept_terms: Yup.boolean().required().isTrue(),
});

interface IUserRegistrationExtraInfoProps {
    isSubmitting: boolean;
    formData: IUpdateMarketingProfile;
    onSetData: (data: IUpdateMarketingProfile) => void;
    onPreviousStep: () => void;
    onSubmit: (data: IUpdateMarketingProfile) => void;
}

export const UserRegistrationExtraInfo = ({isSubmitting, formData, onSetData, onPreviousStep, onSubmit}: IUserRegistrationExtraInfoProps) => {
    const {t} = useTranslation();

    const {handleSubmit, formState: {errors}, register, getValues} = useForm<IUpdateMarketingProfile>({
        resolver: yupResolver(userRegistrationExtraInfoSchema),
        defaultValues: formData,
    });

    const onBack = () => {
        onSetData({...formData, ...getValues()});
        onPreviousStep();
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <fieldset className="mb-3">
                <Form.Group className="mb-3">
                    <Form.Label className="h3">Interests</Form.Label>
                    <Form.Text className="text-muted">
                        Please select all that apply
                    </Form.Text>
                    <Row>
                        {Object.values(ProfileInterests).map((interest, index) => (
                            <Col sm={6} key={interest}>
                                <Form.Check
                                    id={interest}
                                    type="checkbox"
                                    label={interest.charAt(0).toUpperCase() + interest.slice(1).toLowerCase()}
                                    style={{fontSize: '1.15rem'}}
                                    value={interest}
                                    {...register('interests')}
                                />
                            </Col>
                        ))}
                    </Row>
                </Form.Group>
            </fieldset>
            <Form.Group controlId="accept_terms" className="mb-1 d-flex align-items-center">
                <Form.Check
                    className="form-control-sm align-bottom"
                    type="checkbox"
                    label={t(
                        'userRegistrationInfo.form.accept_terms',
                        'I have read the terms and conditions on the Young Eyes website.',
                    )}
                    style={{fontSize: '1rem'}}
                    {...register('accept_terms')}
                />
                <a href={Config.TCLinkUrl} target="_blank" style={{fontSize: '1rem'}} rel="noreferrer">
                    {t('userRegistrationInfo.form.link', 'See here')}
                </a>
            </Form.Group>
            <FormError name="accept_terms" errors={errors} />
            <div className="d-flex mt-4">
                <Button
                    className='m-0 mr-auto'
                    variant="secondary"
                    onClick={onBack}
                    size="lg"
                    style={{margin: '0 auto', display: 'block', fontSize: 'larger'}}
                    disabled={isSubmitting}
                >
                    {t('common.back', 'Back')}
                </Button>
                <Button
                    className='m-0'
                    variant="primary"
                    type="submit"
                    size="lg"
                    style={{margin: '0 auto', display: 'block', fontSize: 'larger'}}
                    disabled={isSubmitting}
                >
                    {t('common.submit', 'Submit')}
                </Button>
            </div>
        </Form>
    );
};