import axios from 'axios';

import {
    ISingleUseSignup,
    ISingleUseSignupListQuery,
    ISingleUseSignupListResult,
    ISingleUseSignupSummary,
} from 'modules/single-use-signup/models';
import {Config} from 'shared/config';
import {getAxiosOptions} from 'shared/utils/api';
import {getAccessTokenSilently} from 'shared/utils/token';

const getSingleUseSignupUrl = (tenantId: number, path: string) => {
    return `${Config.userServiceURL}/tenants/${tenantId}/single_use_signups${path}`;
};

export const readSingleUseSignup = async (
    tenantId: number,
    singleUseSignupId: number,
): Promise<ISingleUseSignup> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(
        getSingleUseSignupUrl(tenantId, `/${singleUseSignupId}`),
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const readSingleUseSignupList = async (
    tenantId: number,
    singleUseSignupListQuery: ISingleUseSignupListQuery,
) : Promise<ISingleUseSignupListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(
        getSingleUseSignupUrl(tenantId, '/'), {
            params: singleUseSignupListQuery,
            ...getAxiosOptions(accessToken),
        },
    );
    return response.data;
};

export const readSingleUseSignupSummary = async (
    tenantId: number,
    singleUseSignupId: number,
): Promise<ISingleUseSignupSummary> => {
    const response = await axios.get(
        getSingleUseSignupUrl(tenantId, `/${singleUseSignupId}/summary`),
    );
    return response.data;
};

export const expireSingleUseSignup = async (tenantId: number, singleUseSignupId: number, userId: number) => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getSingleUseSignupUrl(tenantId, `/${singleUseSignupId}/expire`),
        {
            user_id: userId,
        },
        getAxiosOptions(accessToken),
    );
    return response.data;
};