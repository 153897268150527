import React from 'react';

import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import {formatAxiosErrorOrThrow} from 'shared/utils/error';
import {registerSchool} from 'modules/tenant/api';
import {useTenantInfo} from 'modules/tenant/hooks';
import {useSingleUseSignupSummaryFromUrl} from 'modules/single-use-signup/hooks/useSingleUseSignupSummaryFromUrl';

import {FullScreenModal} from 'shared/components/FullScreenModal/FullScreenModal';
import {FullScreenModalTitle} from 'shared/components/FullScreenModal/FullScreenModalTitle';
import {ICreateTenantForm, RegisterSchoolForm} from 'shared/pages/ConfigureSchool/RegisterSchoolForm/RegisterSchoolForm';
import {EmailVerification} from 'shared/pages/Signup/EmailVerification';
import {SelectSchoolDropdown} from 'shared/pages/ConfigureSchool/SelectSchoolDropdown';
import { useNavigate } from 'react-router-dom';

export const ConfigureSchool = () => {
    const {t} = useTranslation();
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [emailConfirmation, setEmailConfirmation] = React.useState<string | undefined>('asherthenoble@gmail.com');
    const [showRegistrationForm, setShowRegistrationForm] = React.useState<boolean>(false);

    const tenantInfo = useTenantInfo().tenantInfo;

    const navigate = useNavigate();

    const {singleUseSignupSummary} = useSingleUseSignupSummaryFromUrl(tenantInfo.id);

    const onSubmitForm = (form: ICreateTenantForm) => {
        if (isSubmitting || !singleUseSignupSummary || !emailConfirmation) {
            return;
        }
        setIsSubmitting(true);
        (async () => {
            try {
                await registerSchool(
                    form,
                    tenantInfo.id,
                    singleUseSignupSummary.id,
                );
                toast.success(t('registerSchool.success', 'School registration successful!'));
                navigate('/register-school-success');
            } catch (e) {
                toast.error(formatAxiosErrorOrThrow(t(
                    'registerSchool.submit.error', 'There was an error',
                ), e));
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    if (!emailConfirmation) return (
        <FullScreenModal close={false} size="md">
            {singleUseSignupSummary && (
                <EmailVerification
                    setEmail={setEmailConfirmation}
                    singleUseSignupId={singleUseSignupSummary.id}
                    tenantId={tenantInfo.id}
                />
            )}
        </FullScreenModal>
    );

    return (
        <FullScreenModal close={false} size="md">
            {!showRegistrationForm ? (
                <SelectSchoolDropdown
                    tenantId={tenantInfo.id}
                    onToggle={() => setShowRegistrationForm(true)}
                />
            ) : (
                <>
                    <FullScreenModalTitle className="mb-4">
                        {t('common.registerSchool.title', 'Register to Young Eyes as a school')}
                    </FullScreenModalTitle>
                    <RegisterSchoolForm
                        onSubmit={onSubmitForm}
                        onToggle={() => setShowRegistrationForm(false)}
                        isSubmitting={isSubmitting}
                    />
                </>
            )}
        </FullScreenModal>
    );
};