import React from 'react';

import {yupResolver} from '@hookform/resolvers/yup';
import {Button, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {verifyEmail} from 'modules/user/api/email';
import {formatAxiosErrorOrThrow} from 'shared/utils/error';

import './styles.scss';

const emailConfirmationSchema = Yup.object().shape({
    confirmEmail: Yup.string().email(),
});

interface IEmailConfirmationSchema {
    confirmEmail: string;
}

interface IEmailConfirmationProps {
    tenantId: number;
    singleUseSignupId: number;
    setEmail: (val: string) => void;
}

export const EmailVerification = ({setEmail, singleUseSignupId, tenantId}: IEmailConfirmationProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const {t} = useTranslation();

    const {handleSubmit, formState: {errors}, register} = useForm<IEmailConfirmationSchema>({
        resolver: yupResolver(emailConfirmationSchema),
        defaultValues: {
            confirmEmail: '',
        },
    });

    const onSubmit = (data: IEmailConfirmationSchema) => {
        setIsSubmitting(true);
        (async () => {
            try {
                await verifyEmail(
                    tenantId,
                    {
                        host: document.location.host,
                        single_use_signup_id: singleUseSignupId,
                        email: data.confirmEmail,
                    },
                );
                setEmail(data.confirmEmail);
            } catch (error) {
                toast.error(formatAxiosErrorOrThrow(t(
                    'emailConfirmation.error',
                    'Error confirming email',
                ), error));
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex align-items-center justify-content-between">
                <Form.Label className="ConfirmEmail--text mb-1">
                    {t('emailConfirmation.confirmEmailAddress', 'Confirm Email Address')}
                </Form.Label>
                <span className="ConfirmEmail--text text-muted mb-1">
                    {t('emailConfirmation.test', 'Use the same email used when purchasing the course.')}
                </span>
            </div>
            <Form.Group controlId="emailConfirmation.email" className="mb-3">
                <Form.Control
                    type="email"
                    placeholder={t('emailConfirmation.confirmEmailAddress', 'Enter your email address...')}
                    isInvalid={!!errors.confirmEmail}
                    {...register('confirmEmail')}
                />
                {errors.confirmEmail && (
                    <Form.Control.Feedback type="invalid">
                        {errors.confirmEmail.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <div className="text-center">
                <Button className="ConfirmEmail--submit-button" type="Submit" variant="primary" disabled={isSubmitting}>
                    {isSubmitting ? (
                            t('common.confirm', 'Confirming...', {context: 'busy'})
                        ) : (
                            t('common.confirm', 'Confirm')
                    )}
                </Button>
            </div>
        </Form>
    );
};