import React from 'react';

import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {Button, Dropdown} from 'react-bootstrap';

import {formatAxiosErrorOrThrow} from 'shared/utils/error';
import {LoadingError} from 'shared/components/loading/LoadingError/LoadingError';
import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';

import {readDomainSummary} from 'modules/domain/api';
import {ITenantSummary} from 'modules/tenant/models';
import {ITenantListSummaryFilters} from 'modules/tenant/state/tenant-list-summary';
import {useTenantListSummaryLoadable} from 'modules/tenant/hooks/useTenantListSummaryLoadable';
import {useSingleUseSignupSummaryFromUrl} from 'modules/single-use-signup/hooks/useSingleUseSignupSummaryFromUrl';

import './styles.scss';

export interface ISelectSchoolDropdownProps {
    tenantId: number;
    onToggle: () => void;
}

export const SelectSchoolDropdown = ({onToggle, tenantId}: ISelectSchoolDropdownProps) => {
    const {t} = useTranslation();
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [selectedSchool, setSelectedSchool] = React.useState<ITenantSummary | undefined>();

    // TODO: fix this
    const [filters, setFilters] = React.useState<ITenantListSummaryFilters>({
        filters: {
            limit: 50,
        },
        page: 0,
    });

    const {tenantSummaries, loading, error} = useTenantListSummaryLoadable(filters);

    const {singleUseSignupSummary} = useSingleUseSignupSummaryFromUrl(tenantId);

    const handleSelection = async () => {
        if (!singleUseSignupSummary) {
            toast.error(t('singleUseSignupSummary.notFound', 'Unique Signup Link could not be found'));
            return;
        }
        if (!selectedSchool) {
            toast.error('Must select school before confirming selection');
            return;
        }
        setIsSubmitting(true);
        try {
            const domainSummary = await readDomainSummary(selectedSchool.id);
            // window.location.href = `https://${domainSummary?.host}/join/teacher/${singleUseSignupSummary.id}`;
            window.open(`https://${domainSummary?.host}/join/teacher/${singleUseSignupSummary.id}`);
            toast.success('School succesfully selected');
        } catch (e) {
            toast.error(formatAxiosErrorOrThrow(t(
                'getDomains.error', 'There was an error',
            ), e));
        } finally {
            setIsSubmitting(false);
        }
    };

    if (loading) {
        return (
            <LoadingSpinner />
        );
    } else if (error) {
        return (
            <LoadingError />
        );
    } else {
        return (
            <>
                <div className="d-flex">
                    <Dropdown className="Select--dropdown-button">
                        {selectedSchool ? (
                            <Dropdown.Toggle
                                className="w-100 text-dark Dropdown-toggle"
                            >
                                {selectedSchool.name}
                            </Dropdown.Toggle>
                        ) : (
                            <Dropdown.Toggle
                                className="w-100 Dropdown-toggle"
                            >
                                {t('common.select', 'Select School')}
                            </Dropdown.Toggle>
                        )}
                        <Dropdown.Menu className="w-100">
                            {tenantSummaries?.tenant_summaries.map((tenant) => (
                                <Dropdown.Item
                                    key={tenant.id}
                                    onClick={() => setSelectedSchool(tenant)}
                                >
                                    {tenant.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button
                        className="Select--confirm-button"
                        onClick={() => (handleSelection())}
                        disabled={!selectedSchool || isSubmitting}
                    >
                        {isSubmitting ? (
                            t('common.confirm', 'Confirming...', {context: 'busy'})
                        ) : (
                            t('common.confirm', 'Confirm')
                        )}
                    </Button>
                </div>
                <div className="text-center">
                    <Button
                        className="Select--redirect-to-registration-form"
                        onClick={onToggle}
                        variant="secondary"
                    >
                        {t('registerSchool.registerNewSchool', 'Register new school?')}
                    </Button>
                </div>
            </>
        );
    }
};