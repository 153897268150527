import {Nav} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {
    faBell,
    faCog,
    faFolderOpen,
    faLink,
    faListAlt,
    faQuestionCircle,
    faShieldAlt,
    faSignOutAlt,
    faTag,
    faTh,
    faUserGroup,
    faUsers,
    faWrench,
} from '@fortawesome/free-solid-svg-icons';

import {Config} from 'shared/config';
import {ITokenData, Scope} from 'modules/auth/models';
import {useUnreadNotificationCount} from 'modules/notification/hooks/useUnreadNotificationCount';

import {NavbarSidemenuItem} from './NavbarSidemenuItem';
import {NavbarSidemenuDivider} from './NavbarSidemenuDivider';

interface INavbarSidemenuNavProps {
    tenantId: number;
    tokenData: ITokenData;
}

export const NavbarSidemenuNav = ({tenantId, tokenData}: INavbarSidemenuNavProps) => {
    const {t} = useTranslation();
    const unreadCount = useUnreadNotificationCount({
        tenantId,
        userId: tokenData.id,
    });

    const isAdmin = tokenData.scopes.includes(Scope.TenantEdit);

    return (
        <Nav variant="pills" className="flex-column">
            <NavbarSidemenuItem icon={faTh} to="/" userScopes={tokenData.scopes}>
                {t('common.dashboard', 'Dashboard')}
            </NavbarSidemenuItem>
            <NavbarSidemenuItem icon={faBell} to="/notifications" badge={unreadCount} userScopes={tokenData.scopes}>
                {t('common.notifications', 'Notifications')}
            </NavbarSidemenuItem>
            <NavbarSidemenuItem
                icon={faListAlt}
                to="/courses"
                userScopes={tokenData.scopes}
                scopes={[Scope.TenantEdit]}
            >
                {t('common.manageCourses', 'Manage Courses')}
            </NavbarSidemenuItem>
            <NavbarSidemenuItem
                icon={faUserGroup}
                to="/classes"
                userScopes={tokenData.scopes}
                scopes={[Scope.TenantEdit]}
            >
                {t('common.manageClasses', 'Manage Classes')}
            </NavbarSidemenuItem>
            <NavbarSidemenuItem
                icon={faUsers}
                to="/users/students"
                userScopes={tokenData.scopes}
                scopes={[Scope.TenantEdit]}>
                {t('common.students', 'Students')}
            </NavbarSidemenuItem>
            <NavbarSidemenuItem
                icon={faShieldAlt}
                to="/users/administrators"
                userScopes={tokenData.scopes}
                scopes={[Scope.TenantEdit]}>
                {t('common.administrators', 'Administrators')}
            </NavbarSidemenuItem>
            {/* Hidden until implemented on the backend
            <NavbarSidemenuItem icon={faList} to="/courses/curriculums" userScopes={tokenData.scopes} scopes={[Scope.TenantEdit]}>
                {t('common.manageCurriculums', 'Manage Curriculums')}
            </NavbarSidemenuItem> */}
            <NavbarSidemenuItem
                icon={faFolderOpen}
                to="/files"
                userScopes={tokenData.scopes}
                scopes={[Scope.TenantEdit]}
            >
                {t('common.fileManager', 'File Manager')}
            </NavbarSidemenuItem>
            <NavbarSidemenuItem icon={faTag} to="/users/tags" userScopes={tokenData.scopes} scopes={[Scope.TenantEdit]}>
                {t('common.tags', 'Tags')}
            </NavbarSidemenuItem>
            <NavbarSidemenuItem icon={faLink} to="/users/signup/links" userScopes={tokenData.scopes} scopes={[Scope.TenantEdit]}>
                {t('common.signupLinks', 'Signup Links')}
            </NavbarSidemenuItem>
            <NavbarSidemenuItem icon={faCog} to="/settings" userScopes={tokenData.scopes}>
                {t('common.accountSettings', 'Account Settings')}
            </NavbarSidemenuItem>
            <NavbarSidemenuItem icon={faWrench} to="/system" userScopes={tokenData.scopes} scopes={[Scope.TenantEdit]}>
                {t('common.systemSettings', 'System Settings')}
            </NavbarSidemenuItem>
            <NavbarSidemenuItem icon={faSignOutAlt} to="/logout" userScopes={tokenData.scopes}>
                {t('common.logOut', 'Log Out')}
            </NavbarSidemenuItem>
            <NavbarSidemenuDivider />
            <NavbarSidemenuItem icon={faQuestionCircle} href={Config.helpLinkUrl} newTab>
                Help
            </NavbarSidemenuItem>
        </Nav>
    );
};
