import axios from 'axios';

import {getAccessTokenSilently} from 'shared/utils/token';
import {getAxiosHeaders, getAxiosOptions, getQueryIdsParams} from 'shared/utils/api';
import {
    IBatchDeleteUsers,
    IBatchUserResult,
    IUpdateUserInput,
    IUser,
    IUserBatchQuery,
    IUserCountResult,
    IUserCreate,
    IUserIdsListQuery,
    IUserIdsListResult,
    IUserListQuery,
    IUserListResult,
    IUserSummary,
} from '../models/user';
import {Config} from 'shared/config';

export const userProfilePictureSize = 600;

export const getFullName = ({firstname, lastname}: { firstname: string, lastname: string }) => {
    return `${firstname} ${lastname}`;
};

export const getFullNameMaybe = (user: { firstname: string, lastname: string } | undefined) => {
    return user ? `${user.firstname} ${user.lastname}` : undefined;
};

export const getUserUrl = (tenantId: number, path: string) => {
    return `${Config.userServiceURL}/tenants/${tenantId}/users${path}`;
};

export async function readUser(tenantId: number, userId: number): Promise<IUser> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getUserUrl(tenantId, `/${userId}`), getAxiosOptions(accessToken));
    return response.data;
}

export async function readUserList({tenantId, tag_ids, ...params}: IUserListQuery): Promise<IUserListResult> {

    // manually serialize the URL parameters. This is required because axios serializes list parameters
    // differently to our API. Axios results in "course_ids[]=1&course_ids[]=2", whereas we need
    // "course_ids=1&course_ids=2".
    const readUserListURL = getQueryIdsParams({tag_ids});
    
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getUserUrl(tenantId, `/${readUserListURL}`), {
        params,
        ...getAxiosOptions(accessToken),
    });
    return response.data;
}

export async function updateUser({tenantId, userId, updateData}: IUpdateUserInput): Promise<IUser> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(getUserUrl(tenantId, `/${userId}`), updateData, getAxiosOptions(accessToken));
    return response.data;
}

export async function createUser(tenantId: number, userData: IUserCreate): Promise<IUser> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getUserUrl(tenantId, '/'), userData, getAxiosOptions(accessToken));
    return response.data;
}

export const batchDeleteUsers = async ({tenantId, ...params}: IBatchDeleteUsers) => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getUserUrl(tenantId, '/batch/delete'), params, getAxiosOptions(accessToken));
    return response.data;
};

export const readUserSummary = async (tenantId: number, userId: number): Promise<IUserSummary> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getUserUrl(tenantId, `/${userId}/summary`), getAxiosOptions(accessToken));
    return response.data;
};

export const archiveUser = async (tenantId: number, userId: number) => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getUserUrl(tenantId, `/${userId}/archive`), getAxiosOptions(accessToken));
    return response.data;
};

export const readUserCount = async ({tenantId, tag_ids, ...params}: IUserListQuery): Promise<IUserCountResult> => {
    // manually serialize the URL parameters. This is required because axios serializes list parameters
    // differently to our API. Axios results in "course_ids[]=1&course_ids[]=2", whereas we need
    // "course_ids=1&course_ids=2".
    const readUserListURL = getQueryIdsParams({tag_ids});

    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getUserUrl(tenantId, `/count${readUserListURL}`), {
        params,
        ...getAxiosOptions(accessToken),
    });
    return response.data;
};

export const readUserIdsList = async (tenantId: number, {
    tag_ids,
    ...params
}: IUserIdsListQuery): Promise<IUserIdsListResult> => {
    // manually serialize the URL parameters. This is required because axios serializes list parameters
    // differently to our API. Axios results in "course_ids[]=1&course_ids[]=2", whereas we need
    // "course_ids=1&course_ids=2".
    const readUserListURL = getQueryIdsParams({tag_ids});

    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getUserUrl(tenantId, `/user_ids${readUserListURL}`), {
        params,
        headers: getAxiosHeaders(accessToken),
    });
    return response.data;
};

export const readUserBatch = async (tenantId: number, params: IUserBatchQuery): Promise<IBatchUserResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getUserUrl(tenantId, '/batch'), params, getAxiosOptions(accessToken));
    return response.data;
};

export async function createUserFromSignupPage(
    tenantId: number,
    signupPageId: number,
    userData: IUserCreate,
): Promise<IUser> {
    const response = await axios.post(
        getUserUrl(tenantId, `/signup/${signupPageId}`),
        userData,
    );
    return response.data;
}

export const registerUser = async (
    tenantId: number,
    userId: number,
): Promise<IUser> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(
        getUserUrl(tenantId, `/${userId}/register`),
        undefined,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const deleteUser = async (
    tenantId: number,
    userId: number,
): Promise<void> => {
    const accessToken = await getAccessTokenSilently();
    await axios.delete(
        getUserUrl(tenantId, `/${userId}`),
        getAxiosOptions(accessToken),
    );
};

export const createUserFromSingleUseSignup = async (
    tenantId: number,
    singleUseSignupId: number,
    userData: IUserCreate,
    emailConfirmation: string,
) => {
    const response = await axios.post(
        getUserUrl(tenantId, `/public/signup/${singleUseSignupId}`),
        userData,
        {
            params: {
                user_email: emailConfirmation,
            },
        },
    );
    return response.data;
};