export enum Scope {
    SystemEdit = 'SYSTEM_EDIT',
    TenantEdit = 'TENANT_EDIT',
    CourseEdit = 'COURSE_EDIT',
    AssignmentEdit = 'ASSIGNMENT_EDIT',
    SelfEdit = 'SELF_EDIT',
    UsersCreate = 'USERS_CREATE',
    UsersDelete = 'USERS_DELETE',
    ProgressEdit = 'PROGRESS_EDIT',
    ProgressView = 'PROGRESS_VIEW',
    AdminCreate = 'ADMIN_CREATE',
    RoleAdmin = 'ROLE_ADMIN',
    RoleTeacher = 'ROLE_TEACHER',
    RoleStudent = 'ROLE_STUDENT',
    ArchivedUserEdit = 'ARCHIVED_USER_EDIT',
    ArchivedCourseEdit = 'ARCHIVED_COURSE_EDIT',
}

export interface ITokenData {
    id: number;
    scopes: Scope[];
}
