import React from 'react';

import {Config} from 'shared/config';

export const NavbarSidemenuFooter = () => (
    <div className="NavbarSidemenu__footer">
        <a href={Config.helpLinkUrl} target="_blank" rel="noreferrer" className="NavbarSidemenu__footer-link">
            <img src="/favicon.ico" alt="Young Eyes" className="NavbarSidemenu__footer-image" />
            <div className="NavbarSidemenu__footer-caption">Young Eyes</div>
        </a>
    </div>
);
