import React from 'react';

import {IMarketingProfile} from '../models/marketing-profile';
import {readProfileAsUser} from '../api';

export const useMarketingProfileAsUser = (tenantId: number, userId: number): IMarketingProfile | undefined => {
    const [marketingProfile, setMarketingProfile] = React.useState<IMarketingProfile | undefined>();
    
    React.useEffect(() => {
        (async () => {
            const profile = await readProfileAsUser(tenantId, userId);
            setMarketingProfile(profile);
        })();
    }, [tenantId, userId]);

    return marketingProfile;
};